import { createClient } from '@supabase/supabase-js'
import {
  PostgrestFilterBuilder,
  PostgrestSingleResponse,
} from '@supabase/postgrest-js'
import { definitions } from '@/types/dto'
import * as strings from './strings'

const publicUrl = process.env.NEXT_PUBLIC_SUPABASE_URL!
const anonKey = process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!

const options = { autoRefreshToken: true }
export const client = createClient(publicUrl, anonKey, options)

export type Query<T> = PostgrestFilterBuilder<T> | PostgrestSingleResponse<T>
export const query = async <T>(q: Query<T>): Promise<T[]> => {
  const { error, data } = await q
  if (error) console.error(error)
  if (!data) return []
  const dat = [data].flat() as T[]
  if (dat.length === 0) return []
  return dat
}

export const tracks = () => client.from<definitions['tracks']>('tracks')
export const tokens = () => client.from<definitions['tokens']>('tokens')
export const users = () => client.from<definitions['users']>('users')
export const musics = () => client.from<definitions['musics']>('musics')

export const rooms = (options: { id?: number } = {}) => {
  const filter = options.id ? [`id=eq.${options.id}`] : []
  const select = [['rooms'], filter].flat().join(':')
  return client.from<definitions['rooms']>(select)
}

export const upvotes = (options: { roomId?: number } = {}) => {
  const filter = options.roomId ? [`room_id=eq.${options.roomId}`] : []
  const select = [['up_votes'], filter].flat().join(':')
  return client.from<definitions['up_votes']>(select)
}

export const paidTracks = (options: { roomId?: number } = {}) => {
  const filter = options.roomId ? [`room_id=eq.${options.roomId}`] : []
  const select = [['paid_tracks'], filter].flat().join(':')
  return client.from<definitions['paid_tracks']>(select)
}

export const deserialize = <T>(object: { [key: string]: any }): T => {
  const final = Object.entries(object).map(([key_, value_]) => {
    const key = strings.camelize(key_)
    if (value_ === null) return [key, value_]
    if (value_ === undefined) return [key, value_]
    if (typeof value_ === 'number') return [key, value_]
    if (typeof value_ === 'boolean') return [key, value_]
    const date_ = new Date(value_)
    if (date_ instanceof Date && !isNaN(date_.getDate())) return [key, date_]
    return [key, value_]
  })
  return Object.fromEntries(final) as T
}
