import React, { FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GetServerSideProps } from 'next'
import { useRouter } from 'next/router'
import Head from 'next/head'
import {
  Button,
  Flex,
  Input,
  Stack,
  useToast,
  VStack,
  Text,
  HStack,
  Divider,
} from '@chakra-ui/react'
import { Session } from '@supabase/supabase-js'
// import { useDispatch } from '@/store/hooks'
// import * as actions from '@/store/actions'
import * as supabase from '@/libs/supabase'
import { UserCredentials } from '@supabase/supabase-js'
import * as headers from '@/libs/headers'
import * as i18n from '@/services/i18n'
import { Logo } from '@/assets/icons/Logo'
import { GoogleIcon } from '@/assets/icons/GoogleIcon'
import strings from '@/strings'

const useAuthStateChange = () => {
  const router = useRouter()
  const onSession = async (_: any, s: Session | null) => {
    if (!s) {
      await supabase.client.auth.refreshSession().catch(() => null)
    } else {
      router.replace('/me')
    }
  }
  useEffect(() => {
    const { data: sub } = supabase.client.auth.onAuthStateChange(onSession)
    const session = supabase.client.auth.session()
    onSession('', session)
    return () => sub?.unsubscribe()
  }, [router])
}

const Header = () => {
  const { t } = useTranslation()
  const name = t(strings.common.appName)
  const login = t(strings.auth.login)
  const title = `${name} - ${login}`
  return (
    <Head>
      <title>{title}</title>
    </Head>
  )
}

const onGoogleClick = async () => {
  const credentials: UserCredentials = { provider: 'google' }
  const params = { redirectTo: window.location.href }
  await supabase.client.auth.signIn(credentials, params)
}

const GoogleSignIn = (props: any) => {
  const { t } = useTranslation()
  return (
    <Button onClick={onGoogleClick} {...props}>
      {t(strings.auth.googleLogin)}
    </Button>
  )
}

const Or = () => {
  const { t } = useTranslation()
  return (
    <HStack>
      <Divider />
      <Text>{t(strings.common.or)}</Text>
      <Divider />
    </HStack>
  )
}

const EmailSignIn = ({ isLogging, setIsLogging }: any) => {
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const toast = useToast({ status: 'success' })
  const onSubmit = async (e: FormEvent<HTMLDivElement>) => {
    try {
      e.preventDefault()
      setIsLogging(true)
      if (email.length === 0) throw new Error()
      const res = await supabase.client.auth.signIn({ email, password })
      if (res.error) {
        const res_ = await supabase.client.auth.signUp({ email, password })
        if (res_.error && res.error) {
          const description = t(
            'You entered a wrong password, or that email is already taken'
          )
          toast({ description, status: 'warning' })
        }
      }
    } catch (error) {
    } finally {
      setIsLogging(false)
    }
  }
  const placeholder = t(strings.auth.myEmail)
  const onChange = (e: any) => setEmail(e.target.value)
  return (
    <Stack as="form" onSubmit={onSubmit}>
      <Input placeholder={placeholder} value={email} onChange={onChange} />
      <Input
        type="password"
        placeholder={'xxxxx'}
        value={password}
        onChange={e => setPassword(e.target.value)}
      />
      <Button
        w="full"
        type="submit"
        isLoading={isLogging}
        size="lg"
        onClick={event => onSubmit(event as any)}
      >
        {t(strings.auth.logNow)}
      </Button>
    </Stack>
  )
}

const IndexPage = () => {
  useAuthStateChange()
  const [isLogging, setIsLogging] = useState(false)
  return (
    <Flex h="100vh" justify="center" align="center">
      <Header />
      <VStack spacing="6">
        <Logo />
        <Stack>
          <GoogleSignIn leftIcon={<GoogleIcon />} isLoading={isLogging} />
          <Or />
          <EmailSignIn isLogging={isLogging} setIsLogging={setIsLogging} />
        </Stack>
      </VStack>
    </Flex>
  )
}

export const getServerSideProps: GetServerSideProps = async ctx => {
  const language = headers.language(ctx.req.headers)
  if (language) await i18n.changeLanguage(language)
  return { props: {} }
}

export default IndexPage
