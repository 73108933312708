export default {
  common: {
    ok: 'OK',
    appName: 'JamBox',
    no: 'No',
    or: 'or',
    settings: 'Settings',
    yes: 'Yes',
    save: 'Save',
    paid: 'Paid',
    back: 'Back',
  },
  auth: {
    receivedEmail: 'You just received an email to connect',
    googleLogin: 'Connect with Google',
    login: 'Login',
    myEmail: 'My email…',
    logNow: 'Login',
    spotifyLogin: 'Login with Spotify',
  },
  me: {
    saveWithSuccess: 'Saved with success!🍺',
    pubName: 'Pub’s name',
  },
  pay: {
    receivedTokens: 'You just received {{totalTokens}} tokens! 🔥',
    aProblemHappened: 'A problem happened during payment',
    buyMusics: 'Buy musics',
    payWithLydia: 'Pay With Lydia',
    lydiaPaymentRequest:
      'You have received a payment request, directly on your Lydia app! Accept it and come back here right after!',
    lydiaNotWorking: "It's not working, I want to retry",
    pay: 'Pay',
    payWithLydiaMessage: '{{amount}} tokens for JamBox',
    payWithCreditCard: 'Pay With credit card',
  },
  playlist: {
    noJukebox: 'The pub doesn’t have any jukebox yet 😢',
    deleteMusic: 'Delete music?',
    voteFailed: "The vote didn't work…",
    networkError: 'A network error occurred. Please retry later.',
  },
  search: {
    backToPlaylist: 'Back to playlist',
    placeholder: 'Artists, musics…',
    musicAdded: 'Music added! 💃',
    anErrorHappened: 'An error happened',
    searchMusic: 'Look for the music of your choice',
    searchMusicSubtitle:
      'If you add it, it will be the next song to be played!',
    easyAsABC: 'Easy as ABC!',
    addWholePlaylist: 'Add whole playlist',
    adding: 'Adding…',
  },
  room: {
    addMusic: 'Add a music',
  },
}
