import React, { SVGProps } from "react";

export const Logo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="210"
    height="70"
    viewBox="0 0 560 731"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M219.383 466.886C318.809 466.886 397.399 398.238 404.404 298.653C404.494 297.383 409.923 241.645 371.26 137.053C358.491 102.509 329.848 57.1558 285.334 0.993546C254.148 28.6422 236.63 71.3041 232.78 128.979C227.005 215.492 271.992 278.516 271.992 339.594C271.992 421.904 196.928 470.216 110.785 437.777C24.6427 405.337 52.6544 298.072 51.1971 307.097C31.5761 428.601 119.956 466.886 219.383 466.886Z"
      fill="url(#paint0_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M55.9588 316.469C55.9588 462.766 175.91 581.362 323.877 581.362C437.319 581.362 522.912 511.654 556.68 413.199C558.293 425.529 559.123 438.121 559.123 450.912C559.123 605.296 438.217 730.448 282.323 730.448C126.429 730.448 0.0517578 605.296 0.0517578 450.912C0.0517578 389.086 21.4619 338.347 55.7778 292.057C56.275 291.386 56.7751 290.717 57.2779 290.05C56.4043 298.743 55.9588 307.555 55.9588 316.469Z"
      fill="url(#paint1_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M258.307 593.054C413.899 629.573 538.887 556.461 556.256 466.692C561.639 438.871 561.639 396.502 536.476 341.532C504.62 271.943 461.736 217.316 438.906 191.899C415.125 165.424 362.611 101.789 281.362 0.993546C313.965 57.1607 337.89 96.4902 353.139 118.982C376.012 152.72 418.678 254.558 379.121 337.301C319.21 462.622 208.804 459.96 147.904 441.33C108.274 429.208 78.5128 404.236 58.6211 366.415C78.5128 490.931 145.075 566.477 258.307 593.054Z"
      fill="url(#paint2_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="226.342"
        y1="0.993546"
        x2="226.342"
        y2="466.886"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FEAB37" />
        <stop offset="1" stopColor="#E81D1B" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="279.587"
        y1="475.06"
        x2="279.587"
        y2="730.448"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F78E4C" />
        <stop offset="1" stopColor="#F03E74" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="382.328"
        y1="219.855"
        x2="278.51"
        y2="594.241"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FA8633" />
        <stop offset="1" stopColor="#E9433F" />
      </linearGradient>
    </defs>
  </svg>
);
